import { useEffect, useState } from 'react';
import App from 'components/templates/app';
import Title from 'components/AuthTitle';
import Section from 'components/Section';
import Loader from 'components/SectionLoader';
import Button from 'components/Button';
import { FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Pagination, Select, Typography } from '@mui/material';
import MobileDeviceCard from 'components/cards/MobileDeviceCard';
import { useTranslation } from 'react-i18next';
import { showErrorToast } from 'features/toastSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchMobileDevices } from 'api/portal';
import StatusSetupDialog from 'components/dialogs/StatusSetupDialog';
import Fade from 'components/Fade';
import styles from './styles.module.css';

const PAGE_SIZE = 8;

function MobileDeviceList() {
  const [devices, setDevices] = useState([]);
  const [isLoadingDevices, setIsLoadingDevices] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterValue, setFilterValue] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('ANY');
  const [loadingError, setLoadingError] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const HOME = t('organization.breadcrumbs.home');
  const MANAGE_DEVICES = t('organization.breadcrumbs.manage-devices');
  const TITLE = t('organization.organization-mobile-devices-list.title-device-list');
  const LOAD_FAILURE = t('organization.organization-mobile-devices-list.loading-failure');

  const breadcrumbs = [
    {
      path: '/',
      title: HOME,
    },
    {
      path: '/org-mobile-devices/devices',
      title: MANAGE_DEVICES,
      isActive: true,
    },
  ];

  const fetchDevices = (page, status = selectedStatus, text = filterValue) => {
    const searchParams = new URLSearchParams();

    searchParams.set('page', page);
    searchParams.set('page_size', PAGE_SIZE);

    const payload = {};

    if (status !== 'ANY') {
      payload.status = status;
    }

    if (text !== '') {
      payload.generic_text = text;
    }

    setLoadingError(false);
    setIsLoadingDevices(true);

    fetchMobileDevices(searchParams, payload)
      .then((response) => {
        const { data } = response;

        setIsLoadingDevices(false);
        setDevices(data.results);
        setCount(Math.ceil(data.count / PAGE_SIZE));
        setCurrentPage(page);
        setLoadingError(false);
      })
      .catch(() => {
        setLoadingError(true);
        setIsLoadingDevices(false);
        dispatch(showErrorToast('organization.organization-mobile-devices-list.loading-failure'));
      });
  };

  useEffect(() => {
    fetchDevices(1);
  }, []);

  const handleClearFilterClick = () => {
    setFilterValue('');
    setSelectedStatus('ANY');
    fetchDevices(1, 'ANY', '');
  };

  const renderDevices = () => {
    if (loadingError) {
      return (
        <Typography marginTop="8px" variant="body2" color="error" data-testid="loading-error">
          {LOAD_FAILURE}
        </Typography>
      );
    }

    if (devices.length === 0) {
      return (
        <Typography marginTop="8px" variant="body2" color="secondary" data-testid="no-results">
          {t('organization.organization-devices.no-active-devices')}
        </Typography>
      );
    }

    return (
      <Grid marginTop="8px" container spacing="12px">
        {devices.map((device) => (
          <Grid key={`device-card-${device.org_device_id}`} item xs={12} sm={6} md={3}>
            <MobileDeviceCard
              device={device}
              sx={{ height: '100%' }}
              onClick={() => navigate(`/org-mobile-devices/devices/${device.org_device_id}/info`)}
              data-testid={`device-card-${device.org_device_id}`}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <App breadcrumbs={breadcrumbs}>
      <Section className={styles.title}>
        <Title>{TITLE}</Title>
        <Button
          className={styles.applyFilter}
          variant="outlined"
          onClick={() => setShowDialog(true)}
          data-testid="register-device-button"
        >
          {t('organization.organization-mobile-devices-list.register-device')}
        </Button>
      </Section>

      <Section className={styles.container}>
        <form autoComplete="off" onSubmit={(event) => event.preventDefault()}>
          <Grid container spacing="12px" data-testid="mobile-devices-filters">
            <Grid item xs={12} sm={3}>
              <FormControl sx={{ margin: 0 }} fullWidth>
                <InputLabel htmlFor="status">
                  {t('organization.organization-mobile-devices-list.filters.trust-level')}
                </InputLabel>
                <Select
                  id="status"
                  onChange={(event) => setSelectedStatus(event.target.value)}
                  value={selectedStatus}
                  data-testid="select-status"
                  inputProps={{ 'data-testid': 'select-status-input' }}
                >
                  <MenuItem value="ANY">
                    {t('organization.organization-mobile-devices-list.filters.all-statuses')}
                  </MenuItem>
                  <MenuItem value="TRUSTED" data-testid="trusted-filter-option">
                    {t('organization.organization-mobile-devices-info.trusted')}
                  </MenuItem>
                  <MenuItem value="NORMAL" data-testid="normal-filter-option">
                    {t('organization.organization-mobile-devices-info.normal')}
                  </MenuItem>
                  <MenuItem value="BLOCKED" data-testid="blocked-filter-option">
                    {t('organization.organization-mobile-devices-info.blocked')}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl sx={{ margin: 0 }} fullWidth>
                <InputLabel htmlFor="filter">
                  {t('organization.organization-mobile-devices-list.filters.device')}
                </InputLabel>
                <OutlinedInput
                  name="search"
                  value={filterValue}
                  onChange={(event) => setFilterValue(event.target.value)}
                  placeholder={t('organization.organization-mobile-devices-list.filters.filter-device-placeholder')}
                  inputProps={{ 'data-testid': 'search-input' }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button variant="outlined" onClick={handleClearFilterClick} data-testid="reset-filters-button" fullWidth>
                {t('organization.organization-devices.button-filter-reset')}
              </Button>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                onClick={() => fetchDevices(1)}
                color="gradient"
                type="submit"
                data-testid="apply-filters-button"
                fullWidth
              >
                {t('organization.organization-devices.button-filter-apply')}
              </Button>
            </Grid>
          </Grid>
        </form>

        <Loader loading={isLoadingDevices}>
          <Fade>{renderDevices()}</Fade>
          <Pagination count={count} page={currentPage} onChange={(event, value) => fetchDevices(value)} />
        </Loader>
      </Section>
      <StatusSetupDialog
        isOpen={showDialog}
        onConfirm={(status) => navigate(`/org-mobile-devices/devices/add?status=${status}`)}
        onClose={() => setShowDialog(false)}
        data-testid="choose-status-dialog"
      />
    </App>
  );
}

export default MobileDeviceList;
