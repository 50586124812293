import { Avatar, Box, Card, CardContent, CardHeader, Divider, Tooltip, Typography } from '@mui/material';
import CardBadge from 'components/CardBadge';
import TagButton from 'components/TagButton';
import { useTranslation } from 'react-i18next';

function UserCard({ onClick, user, ...props }) {
  const { t } = useTranslation();

  const variant = user.is_active ? 'active' : 'danger';

  return (
    <Card onClick={onClick} {...props}>
      <CardBadge variant={variant} lock={!user.is_active} />
      <CardHeader
        avatar={<Avatar src={user.photo} aria-label={`${user.first_name} ${user.last_name}`} />}
        title={
          <Typography
            variant="body2"
            color="primary.light"
            fontWeight="bold"
          >{`${user.first_name} ${user.last_name}`}</Typography>
        }
        subheader={
          <Typography variant="caption" color="secondary" fontWeight="bold">
            {user.email}
          </Typography>
        }
      />
      <Divider />
      <CardContent>
        {user.organizations ? (
          <Box>
            <Typography color="primary.main" variant="body2" fontWeight="600">
              {t('braincare.users.card-organizations')}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', margin: '0' }}>
              {user.organizations.slice(0, 3).map((organization) => (
                <TagButton key={`tag-${user.id}-${organization.name}`} isSelected>
                  {organization.name}
                </TagButton>
              ))}
              {user.organizations.length > 3 && (
                <Tooltip
                  title={
                    <Box>
                      {user.organizations.slice(3).map((org) => (
                        <Typography key={org.name} variant="body2">
                          {org.name}
                        </Typography>
                      ))}
                    </Box>
                  }
                  placement="bottom"
                >
                  <span>
                    <TagButton isSelected style={{ width: '100%' }}>
                      + {user.organizations.length - 3}
                    </TagButton>
                  </span>
                </Tooltip>
              )}
            </Box>
          </Box>
        ) : null}
      </CardContent>
    </Card>
  );
}

export default UserCard;
